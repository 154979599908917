.App {
  text-align: center;
}


.content {
  background-color: #E7EBF0;
  min-height: 100vh;
  display: flex;
  width: 100vw;
  flex-direction: column;
  align-items: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.auth-content {
  justify-content: center;
}



